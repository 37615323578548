.organisation-status-active {
  border-radius: 20px;
  background-color: rgba(#b9e2f4, 0.4);
  padding: 4px 15px;
  height: 28px;
  color: #16b1ff;
  margin-left: auto;
  margin-right: auto;
  width: 90px;
  text-align: center;
}

.organisation-status-deauthorised {
  border-radius: 20px;
  background-color: rgba(#ffa6a6, 0.4);
  padding: 4px 15px;
  height: 28px;
  color: #ff4d4f;
  margin-left: auto;
  margin-right: auto;
  width: 110px;
  text-align: center;
}
