@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.registrar-container {
  .section-row {
    @media (max-width: $md-size) {
      gap: 24px;
    }
  }

  .section-row:nth-child(1) {
    margin-top: 40px;
    @media (max-width: $md-size) {
      margin: 14px 0;
    }
  }

  .section-row:nth-child(2) {
    margin: 80px 0;
    @media (max-width: $md-size) {
      flex-direction: column-reverse;
      margin: 40px 0;
    }
  }

  h2 {
    margin-top: 0;
  }
}
