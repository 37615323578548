@import '../../Styles/variables.scss';

.programme-management {
  .certify-list {
    width: 110px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .profile-icon {
      margin-bottom: 4px;
    }
  }
  .table-actions-section {
    margin-bottom: 0px;

    input {
      font-family: $primary-font-family;
      font-size: 0.8rem;
    }
  }

  .all-check {
    .ant-checkbox-inner::after {
      background-color: rgba(#3a3541, 0.5);
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      background-color: transparent;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(#3a3541, 0.5);
      border: none;
    }
    margin-right: 10px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-input[value='Retired'] + .ant-checkbox-inner {
      background-color: rgba(#3a3541, 0.4);
      border: none;
    }

    .ant-checkbox-input[value='AwaitingAuthorization'] + .ant-checkbox-inner {
      background-color: rgba(#ed4d47, 0.6);
      border: none;
    }

    .ant-checkbox-input[value='Rejected'] + .ant-checkbox-inner {
      background-color: rgba(#3a3541, 0.4);
      border: none;
    }

    .ant-checkbox-input[value='Issued'] + .ant-checkbox-inner {
      background-color: rgba(#16b1ff, 1);
      border: none;
    }

    .ant-checkbox-input[value='New'] + .ant-checkbox-inner {
      background-color: rgba(#76c327, 0.6);
      border: none;
    }
  }

  .action-bar {
    .ant-btn {
      margin-right: 30px;
    }
  }
}
