@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.homepage-footer-container {
  padding: 1rem;
  color: #fff;
  background: $color-primary-dark;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $md-size) {
      display: block;
    }
    @media (max-width: $sm-size) {
      padding: 0;
    }
  }

  .credits {
    max-width: 320px;
    font-size: 0.75rem;
    line-height: 1rem;
    img {
      display: block;
      // height: 56px;
      width: 8rem;
      // margin-bottom: 14px;
    }
  }

  .bottom-menu {
    display: flex;
    gap: 16px;
    @media (max-width: $md-size) {
      margin-top: 36px;
      flex-direction: column;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }

  .footertext {
    color: $dark-text-color;
    // padding: 5px 60px 20px 60px;
    font-size: 0.875rem;
  }
  .footer-raw {
    display: flex;
  }
  .footer-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 100px 0 28px;
    @media (max-width: $md-size) {
      flex-direction: column;
      justify-content: flex-start;
      padding: 42px 0 28px;
    }
  }
  .footertext-bottom {
    color: $dark-text-color;
    // padding: 100px 60px 28px 0px;
    font-size: 0.875rem;
    font-family: 'MuseoSans';
    font-weight: 100;

    .cc {
      margin: 5px;
    }
  }
  .footertext-link-container {
    display: flex;
    color: $dark-text-color;
    // padding: 100px 0 28px;
    font-size: 0.75rem;
    // text-decoration: underline;
    @media (max-width: $md-size) {
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 24px;
    }
  }
  .footertext-links {
    padding: 8px 16px;
    color: $dark-text-color;
    @media (max-width: $md-size) {
      padding-left: 0;
    }
  }
  .divider {
    margin: 24px 0 6px;
    background-color: #ffff;
  }
  .logocontainer {
    display: flex;
    margin-top: 54px;

    .title {
      font-family: 'MuseoSans';
      font-size: 2rem;
      color: $dark-text-color;
      font-weight: 700;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    .title-sub {
      font-weight: 100 !important;
      font-family: 'MuseoSans';
      font-size: 2rem;
      color: $dark-text-color;
    }

    .footer-country-name {
      font-size: 0.875rem;
      color: $dark-text-color;
      font-family: 'MuseoSans';
      margin-left: 0.6rem;
    }
  }

  .credits-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .container-copyright {
    justify-content: flex-end;

    @media (max-width: $sm-size) {
      text-align: center;
      margin-top: 16px;
    }
  }
}
