@import '../../Styles/variables.scss';

.layout-main-container {
  background-color: $background-color;
  height: 100vh;
  overflow: hidden;
}

.layout-container {
  background-color: $background-color;
  height: 100vh;
  overflow: auto;
}

.transfer-freeze-label {
  background-color: $error-color;
  color: white;
  font-size: 14px;
  padding: 5px 20px;
  min-height: 30px;
  vertical-align: middle;
  .pause-circle {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
  .pause-circle-icon {
    margin-right: 10px;
  }
}

