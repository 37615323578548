@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Spectral:wght@700&display=swap');

@font-face {
  font-family: 'Inter';
  src: url('Inter-SemiBold.woff2') format('woff2'),
    /* Only serve WOFF,ttf if necessary. Otherwise,WOFF 2.0 will be served. */
      url('Inter-SemiBold.woff') format('woff'),
    url('Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.woff2') format('woff2'),
    /* Only serve WOFF,ttf if necessary. Otherwise,WOFF 2.0 will be served. */
      url('Inter-Bold.woff') format('woff'),
    url('Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sohne-Breit';
  src: url('SohneBreit-Dreiviertelfett.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.woff2') format('woff2'),
    /* Only serve WOFF,ttf if necessary. Otherwise,WOFF 2.0 will be served. */
      url('Inter-Regular.woff') format('woff'),
    url('Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Medium.woff2') format('woff2'),
    /* Only serve WOFF,ttf if necessary. Otherwise,WOFF 2.0 will be served. */
      url('Inter-Medium.woff') format('woff'),
    url('Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('MuseoSans-100.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('MuseoSans_700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
