
@import "../../Styles/variables.scss";

.filter-menu-item {
  .filter-title {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
}

.table-row-gray{
  background-color: #F0F0F0;
}
