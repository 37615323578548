.profile-icon {
  border-radius: 100%;
  margin-right: 15px;
  font-size: 15px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 26px;
  }
}
