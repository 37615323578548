@import '../../Styles/variables.scss';

.remark {
  padding-top: 7px;
  font-size: 0.75rem;
  .remark-title {
    text-transform: capitalize;
    font-weight: 500;
    color: $body-text-color-light;
  }

  .remark-body {
    font-weight: 400;
    color: $body-text-color-light;
  }
}