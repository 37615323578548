@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.homepage-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $background-color;
  scroll-behavior: smooth;
  color: #333336;
  font-family: $font-family-regular;

  .homepage-hero-content {
    display: grid;
    grid-template-columns: 60% 40%;
    align-self: center;
    padding: 60px 0;
    @media (max-width: $md-size) {
      display: block;
    }
  }

  .homepage-hero-title {
    color: $outside-background-color;
    font-family: $font-family-title;
    font-size: 4rem;
    line-height: 1.1;
    text-transform: uppercase;
    @media (max-width: $md-size) {
      padding-top: 0;
      font-size: 2.5rem;
    }
  }

  .homepage-hero-subtitle {
    margin-top: 20px;
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.65;
  }

  .homepage-hero-button {
    height: 48px;
    width: 130px;
    background-color: $color-secondary;
    text-transform: uppercase;
  }

  .image-container {
    position: relative;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
      url('../../Assets/Images/hero-background.jpg');
    background-size: cover;

    .container {
      display: flex;
      flex: 1;
    }
  }

  .homepage-img-container {
    min-height: calc(100vh - 90px);
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    flex: 1;
    text-align: left;
    @media (max-width: $md-size) {
      height: auto;
    }

    .arrows {
      width: 60px;
      height: 72px;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: 20px;
      @media (max-width: $lg-size) {
        stroke-width: 2px;
        width: 30px;
        height: 36px;
      }
      @media (max-width: $md-size) {
        display: none;
      }
    }

    .arrows path {
      stroke: #ffffff;
      fill: transparent;
      stroke-width: 2.5px;
      animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;

      @media (max-width: $lg-size) {
        stroke-width: 2px;
      }
    }

    @keyframes arrow {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @-webkit-keyframes arrow /*Safari and Chrome*/ {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    .arrows path.a1 {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
    }

    .arrows path.a2 {
      animation-delay: -0.5s;
      -webkit-animation-delay: -0.5s;
    }

    .arrows path.a3 {
      animation-delay: 0s;
      -webkit-animation-delay: 0s;
    }
  }

  .homepage-about-container {
    background-color: $outside-background-color;
    padding: 100px 0;
    @media (max-width: $md-size) {
      padding: 24px 0;
    }

    .title {
      margin-bottom: 20px;
      color: $color-secondary-dark;
      font-size: 3rem;
      font-weight: 700;
      font-family: $font-family-title;
      @media (max-width: $md-size) {
        margin-bottom: 8px;
        font-size: 2rem;
      }
    }

    .homepagebody {
      font-size: 1.125rem;
      line-height: 1.55;

      a {
        color: #0066ff;
        text-decoration: underline;
      }

      ul {
        padding: 0;
        list-style: none;
      }

      li {
        margin: 1em 0;
        padding-left: 24px;
        background: url('../../Assets/Images/list-item-icon.svg') no-repeat 0px 4px;
      }
    }

    .section-row {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 60px;
      align-items: center;
      @media (max-width: $md-size) {
        display: block;
      }
    }

    .section-column {
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
