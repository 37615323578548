@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

@mixin login-input {
  padding: 12px 16px;
  color: $title-text-color;
  border: 1px solid $line-colors;
  border-radius: 8px;
  font-size: 0.875rem;
  &:focus-within {
    border: 1px solid #034d62;
    box-shadow: none;
  }
  &::placeholder {
    color: $color-neutral-400;
    font-size: 0.875rem;
  }
}

.login-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  background-color: $outside-background-color;

  .ant-btn-primary {
    height: 52px;
  }

  .login-input-email {
    .ant-input {
      @include login-input;
    }
  }

  .ant-input-password {
    @include login-input;
  }

  .login-form {
    margin: 32px auto;
    padding: 32px;
    width: 100%;
    max-width: 544px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px;
    @media (min-width: $lg-size) {
      margin: 60px auto;
    }
  }

  .login-text-contents {
    padding-bottom: 24px;
    text-align: center;

    .login-title {
      font-size: 1.75rem;
      line-height: 1;
      font-weight: 600;
    }

    .login-subtitle {
      color: $color-neutral-500;
    }
  }

  .country-name {
    font-size: 0.6rem;
    font-family: 'MuseoSans';
    margin-top: -3px;
  }

  .login-language-selection-container {
    position: absolute;
    bottom: 14px;
    left: 2rem;
    font-weight: regular;
    font-size: 0.8rem;
    color: $body-text-color;

    .login-language-selection-txt {
      font-size: 0.8rem;
      font-weight: 500;
    }

    .ant-select-selector {
      border: none !important;
      color: $body-text-color !important;
      font-size: 0.8rem;
      padding: 0px !important;
    }

    .ant-select-selection-item {
      color: $body-text-color !important;
    }

    .ant-select-arrow {
      color: $body-text-color !important;
    }

    .sep {
      padding: 0px 5px;
    }

    .ant-select {
      width: 100px;
    }
  }

  .login-country-logo {
    flex-direction: column;
    align-items: center;
    display: flex;
    cursor: pointer;

    img {
      margin-top: 20px;
      height: 24px;
      // object-fit: cover;
      // height: auto;
      // width: 3.7rem;
    }
  }

  .login-country-name {
    font-size: 0.6rem;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-top: 1.5vh;
    color: $title-text-color;

    span {
      @media (max-width: $lg-size) {
        text-align: center;
      }
    }
  }

  .forgot-submit-btn-container {
    margin-top: 1rem;
  }

  .forgot-input-fields-container {
    margin-top: 4vh;
    height: 28vh;

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: $dark-text-color;
      border-color: none;
      background: $line-colors;
      text-shadow: none;
      box-shadow: none;
    }

    .logged-out-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.2rem;
      align-items: center;
      justify-content: space-between;

      .info-icon {
        display: flex;
        width: 5%;
      }
      .msg {
        display: flex;
        width: 95%;
        color: $error-color;
        font-size: 0.8rem;
      }
    }
  }

  .login-input-fields-container {
    .confirm-password {
      .ant-form-item-explain-error {
        margin-top: 0.3rem !important;
      }
    }

    .logged-out-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.2rem;
      align-items: center;
      justify-content: space-between;

      .info-icon {
        display: flex;
        width: 5%;
      }
      .msg {
        display: flex;
        width: 95%;
        color: $error-color;
        font-size: 0.8rem;
      }
    }
  }

  .email-success-msg {
    font-size: 14px;
    margin-top: 0.5rem;
    color: $primary-color;
  }

  .login-input-fields {
    .login-error-message-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-form-item {
      margin-bottom: 14px;

      .login-input-email {
        margin-bottom: 0.2rem;
      }

      .ant-form-item-explain-error {
        margin-bottom: 0.2rem;
      }

      #login-details_password_help {
        margin-top: 0.2rem;
      }
    }

    .login-forget-pwd-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login-forget-pwd-txt {
        font-size: 0.875rem;
        color: $color-link;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    .login-bottom-container {
      margin-top: 24px;
      color: $color-neutral-600;
      text-align: center;
      font-size: 1rem;
      a {
        color: $color-link;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    .bottom-forgot-password-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 1.5rem;
      justify-content: center;
      color: $note-light-text;
      font-weight: 400;

      .backto-signin-txt {
        color: $primary-color;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .login-submit-btn-container {
      margin-top: 2rem;
    }

    .login-register-new-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1rem;
      font-size: 0.9rem;
      color: $body-text-color;

      .login-register-new-txt {
        font-size: 0.8rem;
        color: $body-text-color;
        cursor: pointer;

        .login-register-new-txt-span {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }

    .ant-form-item-label {
      label {
        color: $title-text-color;
      }
    }

    .login-input-email {
      input[type='username']:-webkit-autofill,
      input[type='username']:-webkit-autofill:hover,
      input[type='username']:-webkit-autofill:focus {
        -webkit-text-fill-color: $title-text-color;
        -webkit-box-shadow: 0 0 0 30px $outside-background-color inset !important;
      }
    }

    .login-input-password {
      input[type='password']:-webkit-autofill,
      input[type='password']:-webkit-autofill:hover,
      input[type='password']:-webkit-autofill:focus {
        -webkit-text-fill-color: $title-text-color;
        -webkit-box-shadow: 0 0 0 30px $outside-background-color inset !important;
      }
    }
  }

  .ant-select-selector {
    border: none;
    color: $background-color;
  }
}

.forgot-password-container {
  .note-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: $note-light-text;
    text-align: justify;

    .label {
      margin-right: 0.5rem;
    }
  }
}
